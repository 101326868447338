export interface ViewableBubble {
    url: string;
}

function afterViewerEvents(viewer: Autodesk.Viewing.GuiViewer3D, events: any) {
    let promises: any[] = [];
    events.forEach(function (event: any) {
        promises.push(
            new Promise<void>(function (resolve) {
                let handler = function () {
                    viewer.removeEventListener(event, handler);
                    console.log(`Removed event listener for ${event}`);
                    resolve();
                };
                viewer.addEventListener(event, handler);
                console.log(`Added event listener for ${event}`);
            })
        );
    });

    return Promise.all(promises);
}

// function loadModel(viedfwer: Autodesk.Viewing.GuiViewer3D, url: string) {
//     Autodesk.Viewing.Document.load(
//         url,
//         (doc) => {
//             let viewables = doc.getRoot().getDefaultGeometry();
//             viewer.loadDocumentNode(doc, viewables, {});
//         },
//         (error) => console.log(error)
//     );
// }

export const initializeViewer = async (url: string) => {
    const viewerDiv: any = document.getElementById("MyViewerDiv");
    const viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv);

    var options = {
        env: "Local",
    };

    Autodesk.Viewing.Initializer(options, async function onInitialized() {
        viewer.start(url);
        const { ColorPicker } = await import("./ColorPicker");
        ColorPicker.register();

        await afterViewerEvents(viewer, [
            Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
            Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
        ]);
        viewer.loadExtension("ColorPicker");
    });
    return viewer;
};
