import { useEffect, useState } from "react";
import ForgeViewer from "../Components/ForgeViewer/ForgeViewer";

interface DisplayViewerProps {
    printerName: string;
    pageTitle: string;
}
function DisplayViewer(props: DisplayViewerProps) {
    const [, setViewer] = useState<
        Autodesk.Viewing.GuiViewer3D | undefined
    >(undefined);

    useEffect(() => {
        document.title = props.pageTitle;
    }, [props.pageTitle])

    return (
        <ForgeViewer
            setViewer={setViewer}
            url={
                `https://desl8291nkzrz.cloudfront.net/${props.printerName}/output.svf`
            }
        />
    );
}

export default DisplayViewer;
