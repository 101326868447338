import { BrowserRouter as Router, Link, Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Logo from "./Assets/voron_design_logo.png";
import DisplayViewer from "./Pages/DisplayViewer";
// import { ColorPicker } from "./Components/ForgeViewer/ColorPicker";
// import { ColorResult, RGBColor, SketchPicker } from "react-color";
// import { accents } from "./Static/Accents";
// import { testPlastic } from "./Static/testPlastic";
function App() {

    // const [pickerAccentColor, setPickerAccentColor] = useState<RGBColor>({
    //     r: 241,
    //     g: 112,
    //     b: 19,
    //     a: 50,
    // });
    // const [pickerPrimaryColor, setPickerPrimaryColor] = useState<RGBColor>({
    //     r: 241,
    //     g: 112,
    //     b: 19,
    //     a: 50,
    // });
    // const [showAccentPicker, setShowAccentPicker] = useState(false);
    // const [showPrimaryPicker, setShowPrimaryPicker] = useState(false);

    // function onAccentPickerSelect() {
    //     setShowAccentPicker(!showAccentPicker);
    // }
    // function onPrimaryPickerSelect() {
    //     setShowPrimaryPicker(!showPrimaryPicker);
    // }
    // const showDb = () => {
    //     if (viewer) {
    //         let colorPicker = viewer.getExtension("ColorPicker") as ColorPicker;
    //         colorPicker.getSelectedDbId();
    //     }
    // };
    // const hideSelected = () => {
    //     if (viewer) {
    //         let colorPicker = viewer.getExtension("ColorPicker") as ColorPicker;
    //         colorPicker.hideFound(accents);
    //     }
    // };

    // const handleColorChange = (color: ColorResult, ids: number[]) => {
    //     let colorRgb = color.rgb;
    //     if (viewer) {
    //         let colorPicker = viewer.getExtension("ColorPicker") as ColorPicker;

    //         let newColor = new THREE.Color(color.hex);

    //         colorPicker.setColor(
    //             ids,
    //             new THREE.Vector4(
    //                 newColor.r,
    //                 newColor.g,
    //                 newColor.b,
    //                 colorRgb.a
    //             )
    //         );
    //     }
    // };
    return (
        <div className="App" style={{ height: "100%", overflowY: "hidden" }}>
            <Router>
                <GridContainer>
                    <Nav>
                        <NavImage src={Logo} />
                        <NavTitle>Voron Model Viewer</NavTitle>
                        <NavLink to="/voron2.4">Voron 2.4</NavLink>
                        <NavLink to="/voron0.1">Voron 0.1</NavLink>
                        <NavLink to="/switchwire">Switchwire</NavLink>
                        <NavLink to="/trident">Trident</NavLink>
                        {/* <button onClick={() => showDb()}>
                            Log Selected dbs
                        </button>
                        <button onClick={() => hideSelected()}>
                            Hide Accents
                        </button>
                        <ColorButton onClick={onAccentPickerSelect}>
                            <span>Accent</span>
                            <ColorSwatch swatchColor={pickerAccentColor} />
                        </ColorButton>
                        {showAccentPicker && (
                            <ColorPopover>
                                <div
                                    style={{
                                        position: "fixed",
                                        top: "0px",
                                        left: "0px",
                                        right: "0px",
                                        bottom: "0px",
                                    }}
                                    onClick={onAccentPickerSelect}
                                />
                                <SketchPicker
                                    color={pickerAccentColor}
                                    onChange={(color) =>
                                        setPickerAccentColor(color.rgb)
                                    }
                                    onChangeComplete={(color) =>
                                        handleColorChange(color, accents)
                                    }
                                />
                            </ColorPopover>
                        )}
                        <ColorButton onClick={onPrimaryPickerSelect}>
                            <span>Primary</span>
                            <ColorSwatch swatchColor={pickerPrimaryColor} />
                        </ColorButton>
                        {showPrimaryPicker && (
                            <ColorPopover>
                                <div
                                    style={{
                                        position: "fixed",
                                        top: "0px",
                                        left: "0px",
                                        right: "0px",
                                        bottom: "0px",
                                    }}
                                    onClick={onPrimaryPickerSelect}
                                />
                                <SketchPicker
                                    color={pickerPrimaryColor}
                                    onChange={(color) =>
                                        setPickerPrimaryColor(color.rgb)
                                    }
                                    onChangeComplete={(color) =>
                                        handleColorChange(color, testPlastic)
                                    }
                                />
                            </ColorPopover>
                        )} */}
                    </Nav>
                    <ViewerWrapper>
                        <Viewer>
                            <Switch>
                                <Route path="/voron2.4">
                                    <DisplayViewer printerName="voron2.4" pageTitle="Voron 2.4" />
                                </Route>
                                <Route path="/switchwire">
                                    <DisplayViewer printerName="switchwire" pageTitle="Switchwire"/>
                                </Route>
                                <Route path="/voron0.1">
                                    <DisplayViewer printerName="voron0.1" pageTitle="Voron 0.1" />
                                </Route>
                                <Route path="/trident">
                                    <DisplayViewer printerName="trident" pageTitle="Trident"/>
                                </Route>
                                <Redirect from="/" to="/voron2.4">
                                    <DisplayViewer printerName="voron2.4" pageTitle="Voron 2.4"/>
                                </Redirect>
                            </Switch>
                        </Viewer>
                    </ViewerWrapper>
                </GridContainer>
            </Router>
        </div>
    );
}

// const ColorButton = styled.div`
//     display: flex;
//     align-items: center;
//     padding: 5px;
//     background: #fff;
//     border-radius: 1px;
//     box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
//     cursor: pointer;
//     margin: 0 5px;
// `;
// interface ColorSwatchProps {
//     swatchColor: RGBColor;
// }
// const ColorSwatch = styled.div<ColorSwatchProps>`
//     margin: 5px 5px;
//     width: 36px;
//     height: 14px;
//     border-radius: 2px;
//     background: ${(props) =>
//         `rgba(${props.swatchColor.r} , ${props.swatchColor.g}, ${props.swatchColor.b}, ${props.swatchColor.a})`};
// `;

// const ColorPopover = styled.div`
//     position: absolute;
//     z-index: 2;
//     left: 365px;
//     top: 65px;
// `;

const NavImage = styled.img`
    margin: 0 10px;
    height: 3rem;
`;

const NavTitle = styled.h1`
    font-weight: 300;
    font-size: 1.2rem;
    margin: 0 15px;
`;

const NavLink = styled(Link)`
    color: #000;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 300;
    margin: 0 15px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const Viewer = styled.div`
    height: 100%;
    position: relative;
`;
const ViewerWrapper = styled.div`
    margin: 0 1rem 1rem 1rem;
`;

const Nav = styled.div`
    display: flex;
    align-items: center;
`;

const GridContainer = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1fr;
    gap: 0px 0px;
`;
export default App;
