import { useEffect } from 'react';
import { initializeViewer } from './ViewerHelper';
import './Viewer.css';

interface ForgeViewerProps {
    url: string;
    setViewer: any;
}

function ForgeViewer(props: ForgeViewerProps) {
    const { url, setViewer } = props;

    useEffect(() => {
        async function testFn() {
            let viewer = await initializeViewer(url);
            return viewer;
        }
        testFn().then((viewer) => {
            setViewer(viewer);
        });
    }, [url, setViewer]);

    return <div className="Viewer" id="MyViewerDiv" />;
}

export default ForgeViewer;
